// @font-face {
//   font-family: 'Mulish';
//   font-weight: 400;
//   font-style: normal;
//   src: url('../../../assets/fonts/ExtraLight/Mulish-ExtraLight.ttf') format('truetype'),
//     url('../../../assets/fonts/Light/Mulish-Light.ttf') format('truetype'),
//     url('../../../assets/fonts/Regular/Mulish-Regular.ttf') format('truetype'),
//     url('../../../assets/fonts/Medium/Mulish-Medium.ttf') format('truetype'),
//     url('../../../assets/fonts/SemiBold/Mulish-SemiBold.ttf') format('truetype'),
//     url('../../../assets/fonts/Bold/Mulish-Bold.ttf') format('truetype'),
//     url('../../../assets/fonts/ExtraBold/Mulish-ExtraBold.ttf') format('truetype'),
// }

@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800&display=swap');

// Primary font
$primary_font: 'Mulish', sans-serif;

// Font weight
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$exbold: 800;
$black: 900;

// Font size
$font-xs: 12px;
$font-sm: 14px;
$primary-fs: 16px;
$font-md: 20px;
$font-mdn: 24px;
$font-lg: 30px;
$font-xl: 40px;
$font-xxl: 50px;

body {
  font-family: 'Mulish', sans-serif;
  font-size: $primary-fs;
  line-height: 1.7;
}