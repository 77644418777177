.panel-wrapper {
  padding: 0 20px;
  color: var(--app-mono-text);

  >div {
    width: 100%;
    gap: 20px;
  }
}

.scroll-panel {
  max-height: 80vh;
  overflow-y: auto;
}

.spinner-relative {
  .p-progress-spinner {
    position: relative !important;
    width: 40% !important;
    height: 50% !important;
  }
}

.soc-manager-spinner {
  .p-progress-spinner {
    position: absolute !important;
    width: 100% !important;
    height: 50% !important;

    &:before {
      padding-top: 0;
    }
  }
}

.spinner-in-front {
  .p-progress-spinner {
    z-index: 21010;
  }
}

.show-mobile {
  display: none;
}

.white-medium-text-16 {
  color: var(--app-mono-text);
  font-family: 'Mulish';
  font-size: 16px;
  font-weight: 400;
  white-space: pre-wrap;
}

.primary-medium-text-16 {
  color: var(--app-primary-medium-text);
  font-family: 'Mulish';
  font-size: 16px;
  font-weight: 400;
}

.primary-medium-text-14 {
  color: var(--app-primary-medium-text);
  font-family: 'Mulish';
  font-size: 14px;
}

.edit-picture {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  margin-right: 25px;
  cursor: pointer;
}

.lbl-soc-manager-field {
  font-family: "Mulish";
  font-weight: 800;
  font-size: 16px;
  width: 100%;
}

.soc-manager-input-field {
  width: 100%;
  box-shadow: 0 0 8px 1px rgb(0 0 0 / 25%);
  border: none;
  border-radius: 8px;
  height: 42px;
  padding-left: 10px;
  font-size: 16px;
  line-height: 20px;
  font-family: "Mulish";

  &:disabled {
    background: var(--app-primary-medium-text);
    color: var(--app-mono-background);
  }
}

.dsc-text-area,
.venue-rules-text-area {
  width: 100%;
  box-shadow: none;
  border: none;
  border-radius: 8px;
  padding-left: 10px;
  padding-top: 5px;
  font-size: 16px;
  line-height: 20px;
  font-family: 'Mulish';
}

.dsc-text-area {
  min-height: 100px;
  max-height: 100px;
}

.venue-rules-text-area {
  min-height: 42px;
  max-height: 42px;
  padding: 10px;
}

.div-venue {
  height: calc((100% - 80px));
  font-family: 'Mulish';
  font-style: normal;
  letter-spacing: 0.5px;
  color: var(--app-mono-text);

  .venues-header-container {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    gap: 10px;
    align-items: center;

    .lbl-top-venues {
      white-space: nowrap;
      font-weight: 800;
      font-size: 20px;
      vertical-align: top;
      color: var(--app-mono-text);
      text-align: left;
      margin-top: 5px;
    }

    .create-venue-btn {
      grid-column: 3;
      justify-self: end;
      max-width: 240px;
      min-width: 70px;
    }
  }

  .venues-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 3rem;
    margin-top: 45px;
    padding: 3px;

    .card-bottom-radius-none{
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .div-card-venue {
      padding: 20px;
      margin-right: 10px;
      max-width: 270px;
      height: fit-content;
      border: 1px solid var(--app-mono-background);
      background: var(--app-mono-background);
      border-radius: 12px;
      box-shadow: 1px 0px 26px rgba(255, 255, 255, 0.3);

      .venue-info-container {
        display: flex;
        align-items: center;

        .lbl-venue {
          margin-right: 15px;
        }

        .lbl-venue-value {
          font-weight: 700;
          font-size: 16px;
          overflow: hidden;
          max-width: 195px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .venue-logo-container {
        margin-bottom: 14px;

        .venue-logo {
          height: 110px;
          width: 110px;
          border-radius: 50%;
          margin-top: -15px;
        }

        .venue-name {
          padding: 10px;
          font-weight: 700;
          font-size: 32px;
          line-height: 25px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          color: var(--app-mono-text);
          text-decoration: none;
        }
      }
    }
    .instagram-posts-btn{
      margin-right: 10px;
      max-width: 270px;
      width: 100%;
      background-color: var(--app-brand-magenta);
      padding: 10px 0px;

      color: var(--app-mono-text);
      font-weight: 800;
      font-size: 16px;

      border: none;
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;

    }
  }

  .unset-grid-settings {
    grid-template-columns: unset;
  }

  .no-venues-screen {
    height: 70%;
    display: flex !important;
    flex-flow: column;
    text-align: center;
    justify-content: center;
    align-items: center;

    .add-venue-message {
      font-weight: 700;
      font-size: 32px;
    }

    .add-venue-btn {
      max-width: 350px;
    }
  }
}

.div-products-container {
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  max-height: 100%;
  overflow-y: auto;

  .back-to-products {
    a {
      text-decoration: none;
      font-weight: 400;
      font-size: 16px;
      color: var(--app-brand-cyan);

      &:hover {
        text-decoration: underline;
        color: var(--app-brand-cyan);
      }
    }
  }

  .div-products {
    color: var(--app-mono-text);
    width: 100%;

    .details-link {
      color: var(--app-mono-text);
      text-decoration: none;
      font-weight: 800;
      font-size: 20px;

      z-index: 1;
      position: relative;
    }

    .products-container {
      display: flex;
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin: 45px 0;
      padding: 10px;
      align-items: center;

      .div-card-product {
        padding: 20px;
        margin-right: 50px;
        margin-left: 50px;
        margin-bottom: 15px;
        max-width: 165px;
        height: fit-content;
        border: 1px solid var(--app-mono-background);
        background: var(--app-mono-background);
        border-radius: 20px;
        filter: drop-shadow(0px 0px 5px var(--app-mono-text));

        .logo-container {
          height: 110px;
          width: 110px;

          .product-logo {
            width: 100%;
            max-width: 90px;
            padding-bottom: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }
    }
  }
}

.div-list-plans {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(245px, 1fr));
  grid-gap: 30px 30px;

  .vertical-component-card {
    width: 100%;
    max-width: 245px;
    height: auto;
    background: var(--app-mono-background);
    border-radius: 8px;
    border: 2px solid var(--app-mono-text);

    .component-list-card {
      padding: 20px 20px;
      color: var(--app-mono-text);

      .buy-btn {
        width: 100%;
        margin-top: 10px;
      }

      .plan-text {
        font-weight: 800;
        font-size: 20px;
        line-height: 25px;
        display: contents;

        .lbl-name {
          color: var(--app-brand-cyan);
        }

        .lbl-price {
          color: var(--app-brand-magenta);
        }

        .min-h-title {
          min-height: 150px;
        }

        .lbl-title {
          white-space: nowrap;
          font-weight: 400;
          font-size: 16px;
          color: var(--app-secondary-text);
        }

        .lbl-desc {
          resize: none;
          font-weight: 400;
          font-size: 16px;
          color: var(--app-mono-text);
        }

        .lbl-num {
          font-weight: bold;
          font-size: 16px;
          color: var(--app-mono-text);
        }

        .lbl-num-of-ven {
          color: var(--app-mono-text);
          padding: 0 10px 10px;
        }
      }
    }
  }
}

.div-payment {
  .lbl-name {
    color: var(--app-brand-cyan);
  }
  .p-radiobutton .p-radiobutton-box {
    background: transparent;
  }

  .p-radiobutton .p-radiobutton-box.p-highlight{
    background: var(--app-brand-cyan);
    border-color: var(--app-brand-cyan);
  }

  .p-radiobutton .p-radiobutton-box .p-radiobutton-icon{
    background-color: var(--app-mono-background);
  }
}
.div-trial-plan{
  color: white;
  display: grid;
  span{
    color: magenta;
    font-size: 24px;
    font-weight: 800;
  }
}
.div-payment-info-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;

  .lbl-title {
    color: var(--app-brand-magenta);
    font-size: 24px;
    font-weight: 800;
    line-height: 30px;
  }

 
  .div-current-plan{
    .lbl-info {
      font-size:22px;
      font-weight: 400;
      color:var(--app-primary-text);
    }

    .current-plan-price {
      display: inline-flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      margin-left: 10px;
      height: 42px;
      width: 12vw;
      background: rgba(0, 255, 255, 0.3);
      border-radius: 8px;
      font-size:16px;
      font-weight: 800;
      line-height: 10px;
      color: var(--app-primary-text);
    }

    .current-plan{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin:12px 0;
    }
    @media (max-width: 900px) {
      .current-plan{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin:12px 0;
      }

      .current-plan-price {
        display: inline-flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-left: 10px;
        height: 42px;
        width: 50vw;
        background: rgba(0, 255, 255, 0.3);
        border-radius: 8px;
        font-size:16px;
        font-weight: 800;
        line-height: 10px;
        color: var(--app-primary-text);
      }

      .w-45 {
        width:94%;
      }

      .mob-m {
        margin-top:14px;
      }
    }
  }
  .div-status{
    .lbl-info {
      font-size:22px;
      font-weight: 400;
      color:var(--app-primary-text);
    }
  }
  .div-payment-method{
    .lbl-info {
      font-size:22px;
      font-weight: 700;
      color:var(--app-primary-text);
    }
    .payment-metod-list {
      margin:12px 0;
      .payment-metod-card{
        border:1px solid var(--app-brand-cyan);
        padding:24px;
        border-radius:12px;
      }
    }
  }
  .div-invoices{
    .lbl-info {
      font-size:22px;
      font-weight: 700;
      color:var(--app-primary-text);
    }

    .gray-info {
      color:#908D8D;
    }

    .green-info {
      color:#6AC178;
    }

    .invoice-list {
      margin:12px 0;
      .invoice-card{
        border:1px solid var(--app-brand-cyan);
        padding:12px 24px;
        border-radius:12px;
      }
    }
  }


}

.venues-menu {

  .p-menu-overlay {
    background: var(--app-mono-background);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--app-primary-text);
    border-radius: 8px;
    width: 155px;
    font-family: "Mulish";
    place-content: center;
    padding: 0;
    margin-left: 4rem;
    margin-top: 1.5rem;
    box-shadow: 0 0 9px -3px var(--app-secondary-text);
  }

  .p-menu .p-menuitem-link {
    cursor: pointer;
    display: block;
    text-decoration: none;
    text-align: center;
    padding: 10px;

    .p-menuitem-text {
      color: var(--app-primary-text);
    }

    &:not(.p-disabled):hover {
      background: var(--app-primary-text);

      .p-menuitem-text {
        color: var(--app-mono-background);
      }
    }

    &:focus {
      box-shadow: none;
    }
  }

  div>ul>li:first-child>a {
    &:hover {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  div>ul>li:last-child>a {
    &:hover {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}


.venue-menu {

  .p-menu-overlay {
    padding: 0;
  }
}

.menu-dropdown-helper {
  background: transparent;
  width: 1px;
  display: inline;
  float: left;
  position: relative;
}

.delete-message {
  font-weight: 800;
  font-size: 28px;
  line-height: 36px;
  font-family: "Mulish";
  text-align: center;
  padding: 0 130px;
}

.no-header-dialog {

  .p-dialog-content {
    border-radius: 8px;
  }
}

.custom-dialog-header {
  display: flex;
  align-items: center;
  justify-content: right;
  flex-shrink: 0;
  background: var(--app-mono-background);
  color: var(--app-mono-text);
  padding-right: 1rem;
  margin-top: -1rem;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;

  .custom-dialog-header-txt {
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    font-family: "Mulish";
  }

  .custom-dialog-close-container {
    display: flex;
    align-items: center;

    .custom-dialog-close-btn {
      color: var(--app-mono-text);
      width: 2rem;
      height: 2rem;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      position: relative;
      margin: 0;
      padding: 0;
      text-align: left;
      cursor: pointer;
    }
  }
}

.upload-div {
  border: 1px dashed var(--app-mono-text);
  border-radius: 12px;
  height: 300px;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  font-family: 'Mulish';
}


.upload-your-images-div {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  font-family: 'Mulish';
}

.choose-images-div {
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  font-family: "Mulish";
  margin-top: 10px;
}

.progress-bar-custom-label {
  position: absolute;
  top: 23px;
  left: 10px;
  z-index: 1;
  color: var(--app-mono-text);
  line-height: 1.5rem;
  overflow: hidden;
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block !important;
  font-family: "Mulish";

  &.percentage {
    left: unset;
    right: 10px;
    font-weight: 600;
    font-size: 24px;
  }
}

.progress-bar-custom-label-loaded {
  color: var(--app-mono-text);
  line-height: 1.5rem;
  overflow: hidden;
  width: 20vw;
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block !important;
  font-family: "Mulish";
  margin-left: 10px;
}

.p-progressbar-determinate .p-progressbar-value {
  align-items: center;
  overflow: hidden;
  padding-left: 11px;
  font-family: "Mulish";
  background: #424040;
  font-weight: 400;
  font-size: 18px;
}

.p-progressbar {
  position: relative;
  overflow: hidden;
  height: 72px;
  border-radius: 6px;
  background: #2C2B2B;
}

.delete-image-btn {
  background: none;
  border: none;
  color: var(--app-mono-text);

  &:focus {
    background: none;
    border: none;
    box-shadow: none;
  }

  &:hover {
    background: transparent !important;
    color: var(--app-mono-text) !important;
  }
}

.all-images-div {
  max-height: 360px;
  overflow: auto;
  padding-right: 5px;
}

.loaded-img-container {
  background: #2C2B2B;
  height: 72px;
  border-radius: 6px;
  margin-bottom: 16px;
}

.loaded-image {
  height: 35vw;
  max-height: 72px;
  border-radius: 6px;
}

.dialog-img-cropper {
  width: 550px;
  max-width: 800px;
}

.image-container {
  position: relative;

  span {

    .p-checkbox {
      position: absolute;
      top: 12px;
      left: 17px;

      &:not(.p-checkbox-disabled) {
        .p-checkbox-box.p-focus {
          box-shadow: none;
          border-color: var(--app-mono-background);
        }
      }

      .p-checkbox-box.p-highlight {
        border-color: var(--app-mono-text);
        background: var(--app-mono-text);
        box-shadow: none;

        &:hover {
          border-color: var(--app-mono-background);
          background: var(--app-mono-text);
        }
      }

      .p-checkbox-box {
        border-color: var(--app-mono-text);
        background: transparent;
        border-width: 2px;


        &:hover {
          border-color: var(--app-mono-background);
        }

        .p-checkbox-icon {
          color: var(--app-mono-background);
        }
      }
    }
  }
}

.lbl-checkbox {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
}

.soc-manager-checkbox {
  width: 16px;
  height: 16px;

  &:not(.p-checkbox-disabled) {
    .p-checkbox-box.p-focus {
      box-shadow: none;
      border-color: var(--app-mono-text);
    }
  }

  .p-checkbox-box {
    border-color: var(--app-mono-text);
    background: transparent;
    border-width: 1.5px;
    border-radius: 50%;
    width: 16px;
    height: 16px;

    &.p-highlight {
      border-color: var(--app-mono-text) !important;
      background: var(--app-mono-text) !important;
      box-shadow: none !important;

      &:hover {
        border-color: var(--app-mono-text) !important;
        background: var(--app-mono-text);
      }
    }

    &:hover {
      border-color: var(--app-mono-text) !important;
    }

    .p-checkbox-icon {
      color: var(--app-mono-background);
      font-size: 7px;
      font-weight: 900;
      text-shadow: 0 0.01em, 0.01em 0, 0.01em 0.01em;
      transition-duration: 0s !important;
    }
  }
}

.hosts-title {
    font-weight: 900;
    font-size: 24px;
    // font-family: "Mulish";
}

.add-photos-or-hosts {

  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 50%;

  .lbl-soc-manager-field {
    font-weight: 700;
    font-size: 14px;
  }

  .create-btn {
    border-radius: 6px;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.02em;
  }

  .magenta {
    background: var(--app-brand-magenta);
    border-color: var(--app-brand-magenta);
  }

  .cyan {
    background: var(--app-brand-cyan);
    border-color: var(--app-brand-cyan);
  }
}

.p-datatable .p-datatable-tbody>tr>td {
  border: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 53px !important;
}

.p-datatable .p-datatable-tbody>tr {
  background: #242424;
  color: var(--app-mono-text);
  font-size: 16px;
  font-family: "Mulish";

  &:hover {
    background: #2f2f2f;
  }

  &:focus {
    outline: unset;
  }

  &.p-highlight {
    background: #2f2f2f;
    color: var(--app-mono-text);
  }
}

p-celleditor {

  .p-inputtext {
    padding: 0;
    background: #343333;
    color: var(--app-mono-text);
    border: none;
    font-family: "Mulish";
    font-size: 16px;
  }

  .p-inputtext:enabled:focus {
    box-shadow: none;
  }
}

p-celleditor.p-element {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.lighter {
  background: #343333 !important;
}

.table-btn {
  background: none;
  border: none;
  color: var(--app-mono-text);

  &:focus {
    background: none;
    border: none;
    box-shadow: none;
  }

  &:hover {
    background: transparent !important;
    color: var(--app-mono-text) !important;
  }
}

.hosts-spinner-helper {
  height: 140px;
}

.hosts-table-container {
  max-height: 170px;
  overflow-y: auto;
}

.hosts-table-last-column {
  text-align: right;
  padding-right: 24px !important;
  width: 95px;
}

.hosts-table-first-column {
  padding-left: 24px !important;
}

@media (max-width: 550px) {

  .hosts-table-container {
    max-height: 400px;
    overflow-y: auto;
  }

  p-celleditor.p-element {
    margin-left: 30px;
  }

  p-celleditor {

    .p-inputtext {
      text-align: right;
      padding: 0;
    }
  }

  .hosts-table-last-column {
    border-bottom: 1px solid var(--app-secondary-medium-text) !important;
    padding-right: 0 !important;
  }

  .hosts-table-first-column {
    padding-left: 8px !important;
  }

  .p-datatable-tbody>tr>td {
    padding: 0.5rem 20px !important;
  }
}

.table-checkbox-wrapper {

  width: 40px;
  padding-left: 1.25rem !important;

  .p-checkbox {

    .p-checkbox-box {
      border: 2px solid var(--app-mono-text);
      background: #242424;
      width: 18px;
      height: 18px;

      &.p-highlight {
        border-color: var(--app-mono-text) !important;
        background: var(--app-mono-text) !important;
        box-shadow: none !important;

        &:hover {
          border-color: var(--app-mono-text) !important;
          background: var(--app-mono-text);
        }
      }

      &:hover {
        border-color: var(--app-mono-text) !important;
      }

      .p-checkbox-icon {
        color: var(--app-mono-background);
        font-size: 12px;
        transition-duration: 0s !important;
      }
    }
  }
}

.cyan-btn-large {
  background: transparent;
  border: 1px solid var(--app-brand-cyan);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-size: 18px;

  color: var(--app-brand-cyan);
  width: 100%;
  height: 48px;
}

.special-offer-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin-top: 30px;
}

.products-header {
  display: flex;
  color: var(--app-mono-text);
  flex-direction: column;
  justify-content: center;
  width: auto;
  text-align: center;

  .title {
    font-weight: 800;
    font-size: 40px;
    color: var(--app-brand-magenta);
  }

  .desc {
    font-style: italic;
    font-weight: 300;
    font-size: 20px;
  }
}

.products-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 30px;

  .horizontal-line {
    height: 6px;
    color: var(--app-mono-text);
    opacity: 1;
  }

  .title {

    font-weight: 800;
    font-size: 40px;
    color: var(--app-mono-text);
  }

}

.timelywork-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;

  .desc {
    font-weight: 800;
    font-size: 20px;
    text-align: center;
    color: #23A9E1;
  }

  .logo-container {
    background: var(--app-mono-text);
    border-radius: 12px;
    width: 280px;
    padding: 10px;
    margin: 20px 0;
  }
}

.special-offer-div {
  width: 650px;
  height: 250px;
  border: 2px solid var(--app-brand-cyan);
  border-radius: 17px;
  position: relative;
  padding: 0;
}

.background-image {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 250px;
  background-size: cover;
}

.text-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  color: var(--app-mono-text);
  padding: 40px;
  text-align: left;

  .offer-name {
    font-weight: 800;
    font-size: 32px;
    line-height: 36px;
    color: var(--app-brand-cyan);
  }

  .valid-until-text {
    font-weight: 400;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .offer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: auto;
    padding-left: 4rem;

    .question {
      font-weight: 400;
      font-size: 12px;
      color: var(--app-brand-magenta);
    }

    .project-name {
      font-weight: 800;
      font-size: 16px;
    }

    .price {
      font-weight: 800;
      font-size: 20px;
      color: var(--app-brand-cyan);
    }

    .number-venues {
      font-weight: 200;
      font-size: 20px;
    }

    .free {
      font-weight: 800;
      font-size: 20px;
      color: var(--app-brand-magenta);
    }
  }
}

.float-left {
  float: left !important;
}

.go-to-app-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin-top: 30px;

  .go-to-app-text {
    font-weight: 800;
    font-size: 18px;
    color: var(--app-brand-magenta);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.current-plan-container {
  .label {
    font-weight: 800;
    font-size: 24px;
    color: var(--app-brand-magenta);
  }
}

@media (max-width: 480px) {

  .create-btn {
    width: auto;
    padding: 10px;
    margin-left: 0;
  }

  .div-card-venue {
    height: auto;
    width: auto;
  }

  .hide-mobile {
    display: none;
  }

  .show-mobile {
    display: block;
  }

  .show-mobile-inline {
    display: inline;
  }

  .dialog-long {
    width: 90%;
  }

  .delete-message {
    padding: 0 10px;
  }

  .display-block-mbl {
    display: block !important;
  }

  .w-100-mbl {
    width: 100% !important;
  }

  .w-300-px-mbl {
    width: 300px !important;
    margin-bottom: 0 !important;
  }

  .p-dialog-mask {
    overflow: overlay;
  }

  .w-40-mbl {
    width: 40% !important;
  }

  .p-progress-spinner-svg {
    height: 60% !important;
    width: 60% !important
  }

  .w-65-mbl {
    width: 65% !important;
  }

  .p-b-10-mbl {
    padding-bottom: 10px;
  }

  .justify-content-start-mbl {

    justify-content: start !important;

    .ml-200 {
      margin: 0 !important;
    }
  }

  .img-menu .p-menu-overlay {
    left: 0 !important;
    top: 5.5rem !important;
  }
}

.instagram-placeholder{
  background-color: var(--app-mono-text);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .instagram-icon{
    color: var(--app-mono-background);
  }
  .connect-instagram-btn{
    border: none;
    background-color: transparent;
  }
}

.instagram-posts{
  height: calc((100% - 80px));

  .instagram-posts-header {
    font-family: Mulish;
    font-size: 20px;
    font-weight: 800;
    line-height: 25px;
    color: var(--app-mono-text);
    text-align: center;
    margin-bottom: 10px;
  }
  .back-btn {
    a {
      text-decoration: none;
      font-weight: 400;
      font-size: 16px;
      color: var(--app-brand-cyan);

      &:hover {
        text-decoration: underline;
        color: var(--app-brand-cyan);
      }
    }
  }

  .instagram-posts-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 3rem;
    margin-top: 45px;

    box-sizing: border-box;
    overflow-y: auto;
    height: 100%;

    .instagram-post-card {
      width: fit-content;

      .post-username {
        font-family: Mulish;
        font-size: 16px;
        font-weight: 700;
        color: var(--app-mono-text);
        text-align: left;
        margin-bottom: 2px;
      }

      .post-caption {
        font-family: Mulish;
        font-size: 16px;
        font-weight: 400;
        color: var(--app-mono-text);
        text-align: left;
      }

      .image-wrapper {
        object-fit: cover;
        width: 308px;
        max-height: 317px;
        border-radius: 12px;
      }
    }
  }
}
.connecting-to-instagram-spinner-desc{
  color: var(--app-mono-text);
  font-size: 20px;
}
.add_website_field {
  min-height: 42px;
  max-height: 42px;
  width: 100%;
  box-shadow: none;
  border: none;
  border-radius: 12px;
  margin-right: 10px;
  padding: 4px;
  font-size: 16px;
  line-height: 20px;
  font-family: 'Mulish';
}
.website_form_icon_wrapper {
  width: 25px;
  background-color: transparent;
  border: none;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
}