/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

@import '../src/app/share-modul/style/share-modul.scss';

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  font-family: $primary_font;
  font-size: $primary-fs;
  font-weight: $regular;
  line-height: 1.7;

  background-color: black;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--app-brand-cyan);
  background: -moz-linear-gradient(top, var(--app-brand-cyan) 0%, var(--app-brand-magenta) 100%);
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, var(--app-brand-cyan)), color-stop(100%, var(--app-brand-magenta)));
  background: -webkit-linear-gradient(top, var(--app-brand-cyan) 0%, var(--app-brand-magenta) 100%);
  background: -o-linear-gradient(top, var(--app-brand-cyan) 0%, var(--app-brand-magenta) 100%);
  background: -ms-linear-gradient(top, var(--app-brand-cyan) 0%, var(--app-brand-magenta) 100%);
  background: linear-gradient(to bottom, var(--app-brand-cyan) 0%, var(--app-brand-magenta) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffff', endColorstr='#ff00ff', GradientType=1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

@media (max-width: 480px) {
  html {
    height: 100vh;
    overflow: auto;
    min-height: -webkit-fill-available;
  }

  body {
    min-height: -webkit-fill-available;
  }
}


.c-icons, .c-icons-black {
  width: 20px;
  padding: 0 10px !important;
  height: 1rem;
  background-color: white;
  margin-right: 0.5rem !important;
}

.c-order-details-icon-magenta, .c-calendar-icon-magenta {
  background-color: var(--app-brand-magenta);
}

.c-icons-black {
  background-color: black;
}

.apple-icon {
  -webkit-mask: url("assets/icons/apple-icon.svg") center/cover no-repeat;
  mask: url("assets/icons/apple-icon.svg");
  width: 14px;
}

.fb-icon {
  -webkit-mask: url("assets/icons/fb-icon-circle.svg") center/cover no-repeat;
  mask: url("assets/icons/fb-icon-circle.svg");
  width: 16px;
}

.menu-icon {
  -webkit-mask: url("assets/icons/apps_24px.svg") center/cover no-repeat;
  mask: url("assets/icons/apps_24px.svg");
  width: 48px;
  height: 48px;
}

.social-icon {
  height: 16px;
  background: white;
}

.logout-icon {
  -webkit-mask: url("assets/icons/logout-variant_24px.svg") center/cover no-repeat;
  mask: url("assets/icons/logout-variant_24px.svg");
}
