// Base(d) imports
@import '../style/typography';
@import '../style/colors';

// Responsive mixins
@import '../style/responsive-mixin';

// Socialite Manager Panel Style
@import '/src/app/socialite-manager-panel/style/socialite-manager-panel';


::-webkit-scrollbar {
  width: 5px !important;
  background: black;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, var(--app-brand-magenta) 0%, var(--app-brand-cyan) 100%) !important;
}

.p-component-overlay {
  background-image: linear-gradient(116.91deg, rgba(0, 255, 255, 0.6) 0.05%, rgba(255, 0, 255, 0.6) 99.59%);
  ;
  overflow-y: auto;
}

.p-dialog .p-dialog-header {
  background: var(--app-mono-background);
  color: var(--app-mono-text);
  padding: 20px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.p-dialog .p-dialog-content {
  background: var(--app-mono-background);
  color: var(--app-mono-text);
  padding: 0 0 20px;
  overflow: visible;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  font-family: "Mulish";
}

.p-dialog {
  border-radius: 8px;
  max-height: 100% !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  color: var(--app-mono-text);
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  background-color: var(--app-mono-background);
  color: var(--app-mono-text);
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: none;
}

.dialog-cyan-header-text {
  .p-dialog-title {
    color: var(--app-brand-cyan);
  }
}

.p-tooltip {

  >.p-tooltip-arrow {
    bottom: -5px;
    left: 50%;
    margin-left: -5px;
    border-top-color: var(--app-alert-danger) !important;
    border-width: 5px 5px 5px;
  }

  >.p-tooltip-text {
    font-family: "Mulish";
    padding: 0 10px;
    font-size: 12px;
    font-weight: 400;
    background-color: var(--app-alert-danger)
  }
}

.bg_login {
  background: var(--app-background-image-login);
  height: 100%;
  background-size: cover;
  width: 100vw;
}

.logo_div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 7vh;
}

.logo {
  background: var(--app-background-image-logo) center;
  height: 65px;
  width: 100%;
}

.login_div {
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-height: 100%;
  overflow-y: auto;
}

.sign_up_div,
.company_info_div {
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-height: 100%;
  overflow-y: auto;
  flex-direction: column;

  .logo_div {
    padding-top: 10vh;
  }

  .input_fields {
    width: 700px;
    font-weight: 800;
    font-size: 16px;
  }
}

.btn-outline-transparent {
  border-radius: 8px;
  border: 1px solid var(--app-mono-text);

  height: 42px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  color: var(--app-mono-text);
  font-family: "Mulish";
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;

  &:disabled {
    opacity: 0.7;
  }
}

.btn-gradient {
  font-family: 'Mulish';
  background: linear-gradient(89.1deg, var(--app-brand-magenta) 0%, var(--app-brand-cyan) 100%);
  height: 42px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--app-mono-text);
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  border-radius: 8px;
  border-style: none;

  &:active {
    border-style: none;
  }

  &:disabled {
    opacity: 0.8;
  }
}

.create-btn {
  font-family: 'Mulish';
  background: linear-gradient(89.1deg, var(--app-brand-magenta) 0%, var(--app-brand-cyan) 100%);
  font-weight: 800;
  font-size: 14px;
  line-height: 13px;
  border-radius: 8px;
  height: 42px;
  width: 15vw;
  float: right;
  border-style: none;
  color: var(--app-mono-text);

  &:active {
    border-style: none;
  }

  &:disabled {
    opacity: 0.8;
  }
}

.btn-cancel-no-border {
  background: var(--app-mono-background);
  color: var(--app-mono-text);
  font-family: "Mulish";
  font-weight: 800;
  font-size: 14px;
  border: none;
}

.btn_without_color {
  color: var(--app-mono-text);
  height: 42px;
  border: 1px solid var(--app-mono-text);
  background-color: transparent;
  font-size: 14px;
  font-weight: 800;
  font-family: "Mulish";
  margin-top: 15px;
  border-radius: 8px;
  line-height: 20px;
}

.w-45 {
  width: 45%;
}

.w-20 {
  width: 20%;
}

@media (max-width: 900px) {
  .w-20 {
    width: 35%;
  }
}

.fz-16 {
  font-size: 16px;
}

.error-color {
  color: var(--app-alert-danger);
}

.magenta-color {
  color: var(--app-brand-magenta);
  border-color: var(--app-brand-magenta);

  &:hover {
    color: var(--app-brand-magenta) !important;
  }
}

.cyan-color {
  color: var(--app-brand-cyan);
  border-color: var(--app-brand-cyan);

  &:hover {
    color: var(--app-brand-cyan) !important;
  }
}

.welcome-text-lg {
  background: url("/assets/images/logo/welcome-text-lg.svg") no-repeat center;
  height: 150px;
  width: 100%;
}

.welcome-message-container {
  text-align: center;
  color: var(--app-mono-text);

  .welcome-message {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5px;
  }
}

.product-name {
  font-size: 20px;
  font-weight: 800;
  white-space: nowrap;
}

.login_form {
  font-family: 'Mulish';
  font-style: normal;
  padding: 30px;
  width: 430px;
  height: auto;
  border-radius: 8px;
  color: var(--app-primary-text)
}

.signup_form {
  font-family: 'Mulish';
  font-style: normal;
  padding: 30px;
  height: auto;
  border-radius: 8px;
  color: var(--app-primary-text);
}

.sign_in,
.sign_up,
.company_info_info {
  font-weight: 800;
  font-size: 35px;
  letter-spacing: 2px;
  color: var(--app-brand-cyan);
  text-align: center;
}

.sign_up,
.company_info_info {
  margin-top: 7vh;
}

.login_field {
  display: inline-grid;
  width: 100%;
  font-weight: 800;
  font-size: 16px;
}
.lbl_field_name{
  font-family: Mulish;
  font-size: 16px;
  font-weight: 800;
  text-align: left;
}

.lbl_field {
  height: 30px;
}

.lbl_field_small {
  height: 17px;
}

.input_field {
  border: none;
  border-radius: 8px;
  height: 42px;
  padding-left: 10px;
  font-size: 14px;
  font-family: "Mulish";
}

.btn_login {
  background: linear-gradient(79.63deg, #00FFFF 30.94%, #FF00FF 169.05%);
  border-radius: 8px;
  font-weight: 800;
  font-size: 14px;
  height: 42px;
  color: var(--app-mono-background);
  margin-top: 15px;
  font-family: "Mulish";
  border: none;

  &:disabled {
    opacity: 0.7;
  }
}

.btn_sign_up {
  margin-top: 1.75rem;
}

.btn_login_manager {
  background: linear-gradient(90deg, var(--app-brand-cyan) 0%, var(--app-brand-magenta) 100%);
  border-radius: 8px;
  font-weight: 800;
  font-size: 16px;
  height: 42px;
  color: var(--app-mono-text);
  font-family: "Mulish";
  border: none;

  &:disabled {
    opacity: 0.7;
  }
}

.p_sign_up {
  text-align: right;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--app-mono-text);

  a {
    font-weight: 800;
  }
}

.terms-and-conditions {
  text-decoration: underline;
  color: var(--app-brand-cyan);
  cursor: pointer;
}

.terms-and-conditions-checkbox {

  &:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: var(--app-mono-text);
  }

  &:not(.p-checkbox-disabled) {
    .p-checkbox-box.p-focus {
      box-shadow: none;
      border-color: var(--app-mono-text);
    }
  }

  .p-checkbox-box.p-highlight {
    border-color: var(--app-mono-text);
    background: transparent;
    box-shadow: none;

    &:hover {
      border-color: var(--app-mono-text) !important;
      background: transparent !important;
    }
  }

  .p-checkbox-box {
    border-color: var(--app-mono-text);
    background: transparent;
    border-width: 2px;


    &:hover {
      border-color: var(--app-mono-text);
      opacity: 0.9;
    }

    .p-checkbox-icon {
      color: var(--app-brand-cyan);
      font-weight: 800;
      font-size: 20px;
      margin-left: 7px;
      margin-bottom: 3px;
    }
  }
}

.socialite_manager_navbar {
  height: 100%;
  width: 240px;
  background: #191919;
  color: var(--app-primary-text);
  transition: all 0.75s ease;
  z-index: 2;

  nav {
    padding: 6.5rem 0rem 1.5rem;

    .nav {
      margin-left: 12px;
    }

    @media (max-height: 600px) {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: calc(100% - 80px);
    }
  }

  .ul_menu {
    list-style-type: none;
    padding: 0;
    margin: 30px 0;

    li {
      margin: 20px 0 0;

    }

    .spam-text {
      display: inline-block;
      width: 250px;
      transition: width .75s;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 800;
      font-size: 20px;
      line-height: 30px;
    }

    .active {
      a {
        color: var(--app-brand-cyan);

      }

    }
  }

  a {
    color: #ffffff;
    text-decoration: none;
    min-width: 100%;
    margin: auto;
    display: flex;
    padding: 0;
    height: 30px;
    align-items: center;

    i {
      padding: 0px 20px;
    }
  }
}

.remove-highlight {
  font-weight: 300;
}

.highlight-hashtags {
  color: var(--app-brand-magenta);
}

.soc_manager_header {
  height: 100px;
  width: 100%;
  background: linear-gradient(89.7deg, var(--app-brand-cyan) 0.17%, var(--app-brand-magenta) 99.54%);
  ;
  color: var(--app-primary-text);
  position: fixed;
  z-index: 100;
}

.div_router_outlet {
  padding: 7.5rem 1.5rem 1.5rem;
  width: 100%;
}

#chg_lang {
  letter-spacing: normal;

  .p-dropdown {
    min-width: 105px;
    background: var(--app-mono-background);
    border: 1px solid var(--app-brand-cyan);
    color: var(--app-primary-text);
    box-shadow: none;
    border-radius: 8px;
  }

  .pi {
    font-size: 10px;
    color: var(--app-brand-cyan);
  }

  .country_name {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: var(--app-primary-text);
  }

  .p-dropdown-panel {
    background: var(--app-mono-background);
    border: 1px solid var(--app-brand-cyan);
    border-radius: 8px;
  }

  .p-highlight {
    background: var(--app-mono-background);

    .country_name {
      color: var(--app-brand-cyan);
    }
  }

  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background: (var(--app-mono-background));

    .country_name {
      color: var(--app-brand-cyan);
    }
  }
}

.invalid_input_text {
  float: right;
  font-weight: 400;
  display: inline-block;
  color: var(--app-alert-danger);
  font-size: 14px;
}

.p-password {
  width: 100%;
}

.password-input-field {
  font-family: 'Mulish' !important;
  color: black !important;

  width: 100%;
  box-shadow: none !important;

  .p-inputtext {
    border: none;
  }
}

#sign_up_password_filed .pi {
  font-size: larger;
  color: var(--app-brand-cyan);

  margin: 0;
  top: 25%;
}

.signup-password-tooltip {

  .tooltip-inner {
    border-radius: 8px;
    border: var(--app-brand-cyan) 1px solid;
    max-height: fit-content;
    max-width: 175px;
  }

  .tooltip-arrow {
    &:before {
      border-right-color: var(--app-brand-cyan) !important;
    }
  }

  .password-tooltip-content {
    font-family: 'Mulish';
    font-size: 12px;
    line-height: 16px;
    text-align: left;
  }

  .password-rule-checked {
    list-style-type: '\2713\1';
    color: #60C85B;
  }
}

.change-pass-password-tooltip {
  padding-left: 7px !important;
}

.signup_link {
  text-decoration: none;
  color: white;

  &:hover {
    text-decoration: underline;
    color: white;
  }
}

.right {
  float: right;
}

.header_user {
  position: absolute;
  top: 50%;
  right: 3%;
  transform: translate(0, -50%);
  display: flex;
}

.language_change {
  position: relative;
  top: 50%;
  right: 1%;
  transform: translate(0, -50%);
  display: flex;
  margin-right: 3rem;
}

.user_info {
  text-align: end;
  margin-right: 20px;
  font-family: "Mulish";
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.5px;
  padding: 5px;
  display: inline-grid;

  .user_lbl_name {
    font-weight: 800;
    font-size: 20px;
  }

  .user_lbl_email {
    font-weight: 400;
    font-size: 16px;
  }
}

.user_image {
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

#user_menu {
  .p-menu-overlay {
    top: 90px !important;
    padding: 0px;
    background: black;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    border: 1px solid cyan;
    line-height: 20px;
    color: var(--app-primary-text);
    border-radius: 8px;
  }

  .p-menu .p-menuitem-link {
    cursor: pointer;
    display: block;
    text-decoration: none;
    text-align: center;

    .p-menuitem-text {
      color: var(--app-primary-text);
    }

    &:not(.p-disabled):hover {
      border-radius: 8px;
      background: var(--app-mono-background);

      .p-menuitem-text {
        color: var(--app-brand-cyan);
      }
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.page-not-found {
  height: 80%;

  &__main-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.p-dropdown-item {
  font-family: "Mulish";
}

.country_dropdown {

  .p-dropdown.p-component {
    width: -webkit-fill-available;
    color: var(--app-mono-background);
    padding: 5px 0px;
    line-height: 10px;
    outline: 0 none;
    border-radius: 8px;
    height: 43px;
    font-weight: bold;
    font-size: larger;
    margin-bottom: 15px;
    background: var(--app-mono-text);
  }

  .p-dropdown-label {
    font-family: "Mulish";
    padding: 10px;
    font-weight: 400;
    font-size: 14px;
    color: var(--app-mono-background);
  }

  .p-dropdown-trigger-icon {
    color: #6c757d;
    font-size: 1rem;
    padding-top: 3px;
  }
}

.p-multiselect {
  width: 100%;
  box-shadow: none;
  border: none;
  border-radius: 8px;
  height: 42px;

  &:focus {
    border: none;
  }

  .p-multiselect-label,
  .p-multiselect-item,
  .p-multiselect-empty-message {
    font-family: 'Mulish';
    font-size: 14px;
  }

  .p-multiselect-label {
    padding: 0.55rem 0.5rem;
    color: var(--app-mono-background);
  }

  .p-multiselect-label.p-placeholder {
    padding: 0.55rem 0.5rem;
  }

  &:not(.p-disabled).p-focus {
    box-shadow: none;
  }

  &:not(.p-disabled):hover {
    border: none;
  }

  &.p-multiselect-chip .p-multiselect-token {
    background: black;
    color: white;
    border-radius: 8px;
    font-weight: 400;
  }
}

.p-multiselect-panel {

  .p-multiselect-items {
    padding: 0 !important;
  }

  .p-multiselect-items .p-multiselect-item {
    padding: 0.5rem 0.5rem !important;

    &:focus {
      box-shadow: none;
    }

    &.p-highlight {
      color: var(--app-mono-background);
      background: var(--app-primary-medium-text);
    }

    .p-checkbox {
      & .p-checkbox-box {
        &.p-highlight {
          border-color: var(--app-brand-cyan);
          background: var(--app-brand-cyan);
        }

        & .p-checkbox-icon {
          color: var(--app-mono-background);
          font-size: 16px;
        }
      }

      &:not(.p-checkbox-disabled) .p-checkbox-box:hover {
        border-color: var(--app-brand-cyan);
      }
    }
  }
}

.manager_info {
  font-weight: 800;
  font-size: 20px;
  margin-top: 30px;

  hr {
    background-color: var(--app-mono-text);
    opacity: 1;
    margin: 0;
  }

  hr:not([size]) {
    height: 2px;
  }
}

.menu_bar {
  position: absolute;
  left: 15px;
  display: flex;
  cursor: pointer;
  color: var(--app-mono-background);

  .pi {
    font-size: 35px;
  }
}

.socialite_manager_navbar_in {
  height: 100%;
  width: 0;
  background: var(--app-mono-background);
  color: var(--app-primary-text);
  transition: all .75s ease;

  .logo_header {
    padding: 10px 0;
    width: 0;
    text-align: center;
    z-index: 100;
    background: black;
    transition: all .75s ease;

    img {
      width: 50px;
    }
  }

  .ul_menu {
    .spam-text {
      display: inline-block;
      width: 0%;
      transition: width .75s;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: clip;
    }
  }

  .src_dropdown {
    .p-dropdown-label {
      padding: 10px 0px;
      flex: none;
      display: inline-block;
      width: 0%;
      transition: all .75s ease;
    }
  }
}

.src_dropdown {

  .p-dropdown.p-component {
    border: none;
  }

  .p-dropdown-trigger-icon {
    padding-bottom: 3px;
  }

  .p-focus {
    box-shadow: none !important;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: transparent;
    font-weight: 700;
  }
}

svg {
  width: 2rem;
}

.menu_icon {
  padding: 0px 10px 0px 0px !important;
}

@media (max-width: 700px) {

  .sign_up_div,
  .company_info_div {
    .input_fields {
      width: 60%;
      min-width: 250px;

      .col-6 {
        width: 100%;
      }
    }
  }

  .signup-password-tooltip {

    .tooltip-arrow {
      &:before {
        display: none;
      }
    }

    .tooltip-inner {
      margin-left: -200px;
      margin-top: 150px;
    }
  }
}

@media (max-width: 480px) {

  .mt-4-mlb {
    margin-top: 1.5rem;
  }

  .socialite_manager_navbar {
    position: absolute;
    height: 100vh;

    nav {
      padding: 6.5rem 0 0;
    }
  }

  .socialite_manager_navbar_in {
    position: absolute;
    height: 100vh;
  }

  .user_info {
    display: none;
  }

  .language_change {
    margin-right: 1rem;
  }

  .login_form {
    max-width: 300px;
    padding: 30px 20px;
  }

  .login_div {
    width: 100% !important;
  }

  .logo_div {
    padding-top: 5vh;
  }

  .sign_in {
    font-weight: 700;
    font-size: 25px;
    letter-spacing: 1px;
  }

  .div_router_outlet {
    padding: 7.5rem 1rem 1.5rem;
  }
}

.mb_5 {
  margin-bottom: 5%;
}

.image-cropper-container {
  font-family: "Mulish";
  flex-direction: row;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .container-img {
    width: 100%;
    position: relative;
    text-align: center;
    color: white;
  }

  .img {
    width: 100%;
    height: 130px;
    object-fit: cover;
    border-radius: 8px;
  }

  .img-text-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }

  .upload-img {
    cursor: pointer;
  }

  .cropper-container {
    width: 100%;
    text-align: center;
    height: 68vh;
    border-radius: 8px;
  }

  .cropper-wrapper {
    border-radius: 8px;
    width: 82%;
    height: 55vh;
    margin-right: 3%;
    max-height: 500px;
    background: linear-gradient(137.86deg, var(--app-brand-cyan) 4.52%, var(--app-brand-magenta) 100.04%);
  }

  .preview-wrapper {
    width: 30%;
    border-radius: 8px;
    flex-direction: column;
  }

  .inner-preview-container {
    height: 130px;
    width: 200px;
    border-radius: 8px;
  }

  .preview-img-placeholder {
    height: 130px;
    background: linear-gradient(137.86deg, var(--app-brand-cyan) 4.52%, var(--app-brand-magenta) 100.04%);
    border-radius: 8px;
    justify-content: center;
    align-items: center;
  }

  .preview-label {
    font-weight: 800;
    font-size: 14px;
    line-height: 24px;
    font-family: "Mulish"
  }

  .btn_without_color {
    color: var(--app-brand-cyan);
    height: 42px;
    border: 1px solid var(--app-brand-cyan);
    background-color: transparent;
    font-size: 14px;
    font-weight: 800;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 8px;
  }

  .btn_in_color {
    border: none;
    color: var(--app-mono-background);
    height: 42px;
    background: linear-gradient(79.63deg, var(--app-brand-cyan) 30.94%, var(--app-brand-magenta) 169.05%);
    font-size: 14px;
    font-weight: 800;
    line-height: 37px;
    padding: 0 16px;
    border-radius: 8px;
  }

  .cropper-btn-inner-container {
    max-height: 160px;
    min-height: 150px;
    height: 26vw;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media (max-width: 760px) {

  .image-cropper-container {
    flex-wrap: wrap;
  }

  .cropper-wrapper {
    width: 100% !important;
    margin-right: 0 !important;
    margin-bottom: 20px;
  }

  .hide_mobile_wide {
    display: none;
  }

  .preview-wrapper {
    height: 26vw;
    width: 100% !important;
    flex-direction: row !important;
  }

  .preview-img-placeholder,
  .inner-preview-container {
    height: 26vw !important;
    width: 39vw !important;
  }

  .img {
    height: 26vw !important;
    width: 39vw;
  }

  .container-img {
    width: 50% !important;
    margin-right: 2%;
  }

  .cropper-btn-container {
    width: 50%;
    margin: 0 !important;

    .cropper-btn-inner-container {
      flex-wrap: wrap !important;
      min-height: unset;
      max-height: unset;
    }
  }

  .add-img-placeholder-icon {
    width: 25px;
    height: 25px;
  }

  .add-img-plus-icon {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 550px) {
  .image-cropper-container {
    flex-wrap: wrap;

    .btn_without_color,
    .btn_in_color,
    .btn_rotate_image {
      height: 7vw;
      font-size: 12px;
      line-height: 24px;

      img {
        height: 12px;
        width: auto;
        margin-top: -5px;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.p-inputtext:enabled:focus {
  box-shadow: none;
  border-color: var(--app-brand-cyan);
}

.p-inputtext:enabled:hover {
  box-shadow: none;
  border-color: var(--app-brand-cyan);
}

/* Chrome, Safari, Edge, Opera */
.custom_number_input::-webkit-outer-spin-button,
.custom_number_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.custom_number_input[type=number] {
  -moz-appearance: textfield;
}

.small_spinner_wrapper_no_auth {
  width: 100%;
  height: 40px;
  text-align: center;

  .spinner_small {

    .p-progress-spinner {
      position: relative !important;
      margin: 0 auto !important;
      width: 100px !important;
      height: 60px !important;
      display: inline-block !important;

      &:before {
        padding: 0 !important;
      }
    }
  }
}

.pac-container {
  z-index: 9999 !important;

  .pac-item,
  .pac-item-query {
    font-size: 16px;
    font-family: "Mulish";
  }
}

.change-password-spinner {
  .p-progress-spinner {
    margin-top: 24%;
    position: absolute !important;
    width: 100% !important;
    height: 50% !important;

    &:before {
      padding-top: 0;
    }
  }
}

.spinner-in-front {
  .p-progress-spinner {
    z-index: 21010;

  }
}

.change-password-message {
  font-weight: 900;
  font-size: 32px;
  font-family: "Mulish";
}

.small-header-dialog {

  .p-dialog-header {
    padding: 0;
  }

  .p-dialog-content {
    border-radius: 8px;
    padding: 1rem 0;
  }
}

.change-password-btn {
  font-weight: 800;
  font-size: 16px;
  color: var(--app-mono-background);
  background: var(--app-brand-cyan);
  border: none;
  box-shadow: none;
  border-radius: 8px;
  font-family: "Mulish";
  width: 100%;
  height: 42px;
  margin-top: 27px;
}

.spam-menu {
  cursor: pointer;
  font-size: 1rem;
  float: right;
  padding: 15px;
  color: var(--app-mono-text);
  font-weight: bold;
}

.soc-manager-switch {
  .p-inputswitch-slider {
    box-shadow: unset !important;
  }

  &.p-inputswitch-checked .p-inputswitch-slider {
    background-color: var(--app-secondary-medium-text) !important;
  }
}

.div_terms_and_condition {

  h2,
  h3,
  h4 {
    color: var(--app-brand-cyan) !important;
  }
  padding:40px;
  color: var(--app-primary-text) !important;
}
