:root {
  --app-box-shadow: rgba(0, 0, 0, 0.6);
}

body[data-theme="light"] {
  --app-background-image-logo: url("/assets/images/logo/logotxt.svg") no-repeat ;
  --app-background-image-login: url("/assets/images/logo/no-auth-background.png") no-repeat center;
  --app-background-image: url("/assets/images/logo/backgroundPOS.png") no-repeat;
  --app-logo-main: "/assets/images/logo/Maitre'D-LOGO-for-Black-Background-(200x48px).png";
  --app-mono-background: #000000;
  --app-mono-text: #ffffff;
  --app-primary-text: #ffffff;
  --app-primary-medium-text: #c8c8c8;
  --app-secondary-text: #808080;
  --app-secondary-medium-text: #323232;
  --app-brand-cyan: #00ffff;
  --app-brand-magenta: #ff00ff;
  --app-accent: #ffff00;
  --app-alert-success: #3cb45a;
  --app-alert-danger: #f05a1e;
  --app-premium-bronze: #96785a;
  --app-premium-silver: #7896b4;
  --app-premium-gold: #f0d25a;
  --app-premium-platinum: #b4d2d2;
}

body[data-theme="dark"] {
  --app-background-image-logo: url("/assets/images/logo/logotxt.svg") no-repeat ;
  --app-background-image-login: url("/assets/images/logo/no-auth-background.png") no-repeat center;
  --app-background-image: url("/assets/images/logo/backgroundPOS.png") no-repeat;
  --app-logo-main: "/assets/images/logo/Maitre'D-LOGO-for-Black-Background-(200x48px).png";
  --app-mono-background: #000000;
  --app-mono-text: #ffffff;
  --app-primary-text: #ffffff;
  --app-primary-medium-text: #c8c8c8;
  --app-secondary-text: #808080;
  --app-secondary-medium-text: #323232;
  --app-brand-cyan: #00ffff;
  --app-brand-magenta: #ff00ff;
  --app-accent: #ffff00;
  --app-alert-success: #80ff00;
  --app-alert-danger: #ff8000;
  --app-premium-bronze: #96785a;
  --app-premium-silver: #7896b4;
  --app-premium-gold: #f0d25a;
  --app-premium-platinum: #b4d2d2;
}
